<template>
  <div class="trasaction-regulation">
    {{ regulation }}
  </div>
</template>
<script>
export default {
  props: ['regulation'],
}
</script>
<style scoped>
.trasaction-regulation{
  text-align: center;
  letter-spacing: 0px;
  color: #2C80F3;
  opacity: 1;
}
</style>